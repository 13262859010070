<template>
  <div @click.stop="">
    <audio :id="idPrefix" :src="file.path" />
    <div v-if="!minimal">
      <div class="d-flex justify-content-between align-items-center my-2">
        <h6 class="m-0">{{ file.origin_filename }}</h6>
        <a href="#" class="float-right text-primary" @click.prevent="onClickDelete">удалить</a>
      </div>
      <audio controls :src="file.path" class="w-100 audio" />
    </div>
    <div v-else class="d-flex flex-column align-items-center justify-content-center">
      <small class="text-center text-primary">{{ formattedCurrentTime }}</small>
      <b-icon
        v-if="!isPlaying"
        icon="play-circle"
        class="h2 m-0 text-primary"
        @click.stop="onClickPlay"
      />
      <b-icon v-else icon="pause-circle" class="h2 m-0 text-primary" @click.stop="onClickPause" />
      <small class="text-center text-primary">{{ formattedDuration }}</small>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export default {
  name: 'AudioPlayer',
  props: {
    file: { type: Object, required: true },
    minimal: { type: Boolean, default: false },
  },
  computed: {
    formattedDuration() {
      return dayjs.duration(this.duration * 1000).format('mm:ss');
    },
    formattedCurrentTime() {
      return dayjs.duration(this.currentTime * 1000).format('mm:ss');
    },
  },
  data() {
    return {
      idPrefix: crypto.getRandomValues(new Uint8Array(5)).join(''),
      audioElement: undefined,
      isPlaying: false,
      duration: 0,
      currentTime: 0,
    };
  },
  mounted() {
    this.applyListeners();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    async onClickDelete() {
      const userAgreed = await this.$bvModal.msgBoxConfirm(
        'Вы действительно хотите удалить аудио?',
        {
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
        },
      );

      if (!userAgreed) {
        return;
      }

      this.$emit('delete', this.file);
    },
    applyListeners() {
      this.audioElement = document.getElementById(this.idPrefix);
      this.audioElement?.addEventListener('playing', this.handlePlayingEvent);
      this.audioElement?.addEventListener('loadedmetadata', this.handleLoadedMetadata);
      this.audioElement?.addEventListener('timeupdate', this.handleTimeUpdate);
    },
    removeListeners() {
      this.audioElement?.removeEventListener('playing', this.handlePlayingEvent);
      this.audioElement?.removeEventListener('loadedmetadata', this.handleLoadedMetadata);
      this.audioElement?.removeEventListener('timeupdate', this.handleTimeUpdate);
    },
    handlePlayingEvent() {
      this.isPlaying = true;
    },
    handleLoadedMetadata(event) {
      this.duration = event.target.duration;
    },
    handleTimeUpdate(event) {
      this.currentTime = event.target.currentTime;
    },
    onClickPlay() {
      this.audioElement?.play();
    },
    onClickPause() {
      this.audioElement?.pause();
      this.isPlaying = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.audio {
  &:focus-visible {
    outline: none;
  }
}
</style>
