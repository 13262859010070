<template>
  <div
    class="custom-file"
    @dragover.prevent=""
    @dragenter.prevent=""
    @drop="onDropFile"
    @click="onClickDropBox"
  >
    <div class="custom-file__upload-button">
      <b-icon icon="cloud-arrow-up" variant="primary" class="custom-file__upload-button__icon" />
      <slot>
        <div class="text-primary">Нажмите или</div>
        <small class="text-primary">перетащите файлы</small>
      </slot>
    </div>
    <input
      type="file"
      class="custom-file__input"
      ref="fileInput"
      :accept="fileAccept"
      :multiple="multiple"
      @change="onInputFile"
    />
  </div>
</template>

<script>
import mime from 'mime';

export default {
  name: 'DropBox',
  props: {
    accept: { type: [String, Array], default: '*/*' },
    multiple: { type: Boolean, default: false },
    acceptedOnly: { type: Boolean, default: false },
  },
  computed: {
    fileAccept() {
      return Array.isArray(this.accept) ? this.accept.join() : this.accept;
    },
    acceptedMimes() {
      const acceptArray = Array.isArray(this.accept) ? this.accept : this.accept.split(',');
      const res = [];

      for (const el of acceptArray) {
        const mimeType = typeof el === 'string' && (mime.getType(el) || el);
        mimeType && res.push(mimeType);
      }

      return res;
    },
  },
  methods: {
    onDropFile(event) {
      this.emitInput(event.dataTransfer.files);

      event.preventDefault();
    },
    onInputFile(event) {
      this.emitInput(event.target.files);
    },
    onClickDropBox() {
      this.$refs.fileInput.click();
    },
    emitInput(files) {
      try {
        const rawFiles = this.getRawFilesArray(files);
        const emittedFiles = this.getValidFiles(rawFiles);
        this.$emit('input', emittedFiles);
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    getRawFilesArray(files) {
      if (this.multiple) {
        return files;
      }

      return files[0] ? [files[0]] : [];
    },
    getValidFiles(files) {
      const result = [];
      const errors = [];

      for (const file of files) {
        try {
          if (this.acceptedOnly) {
            this.validateFile(file);
          }
          result.push(file);
        } catch (e) {
          errors.push(e);
        }
      }

      if (errors.length) {
        errors.slice(0, 2).forEach(({ message }) => {
          this.$bvToast.toast(message, { title: 'Ошибка при выборе файла', variant: 'danger' });
        });

        if (errors.length > 2) {
          this.$bvToast.toast(`Не удалось загрузить еще ${errors.length - 2} файла`, {
            title: 'Ошибка при выборе файла',
            variant: 'danger',
          });
        }
      }

      return result;
    },
    validateFile(file) {
      if (this.acceptedMimes.includes('*/*')) {
        return;
      }

      const fileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
      const fileMimeType = mime.getType(fileExtension);

      if (!fileMimeType) {
        throw new Error(`Не возможно определить тип файла: ${file.name}`);
      }

      // Is valid by directly
      if (!this.acceptedMimes.includes(fileMimeType)) {
        const fileMimeTypeGroup = fileMimeType.split('/')[0];

        // Is valid by group
        if (!this.acceptedMimes.includes(`${fileMimeTypeGroup}/*`)) {
          throw new Error(`Файл: ${file.name} не подходит`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-file {
  height: 150px;
  width: 150px;
  min-height: 150px;
  min-width: 150px;
  cursor: pointer;

  &__input {
    visibility: hidden;
  }

  &__upload-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    text-decoration: none;
    opacity: 0.5;
    transition: all 0.2s linear;

    &:hover {
      opacity: 1;
      transition: all 0.2s linear;
    }

    &__icon {
      font-size: 2.5rem;
      line-height: 2rem;
    }
  }
}
</style>
